<template>
  <div class="font-semibold text-[10px] text-typography-disabled mb-1 items-center pl-4 uppercase">
    {{ props.title }}
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  title: {
    required: true,
    type: String,
  },
});
</script>
